<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card>
                    <v-card>
                        <v-card-title class="title" v-if="status == 'default'">
                            <v-icon>payment</v-icon> &nbsp;Monthly Collection Report
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-title class="title" v-if="status == 'today'">
                            <v-icon>payment</v-icon> &nbsp; Students' Collection Report
                            <v-spacer></v-spacer>
                        </v-card-title>

                        <v-card-title class="title">
                            <v-flex xs4 sm4 v-if="status=='default'">
                                <v-select :disabled="gradeLoading" :loading="gradeLoading" :items="grades" class="pa-0"
                                          label="Grade" v-model="grade"/>
                            </v-flex>
                            <v-flex xs4 sm4 v-if="status=='default'">
                                <v-select :disabled="!grade" :items="sections" class="pa-0" label="Section"
                                          v-model="section"/>
                            </v-flex>
                            <v-flex xs4 sm4 v-if="status=='today'">
                                <v-text-field autocomplete="off"
                                              :mask="'####-##-##'"
                                              :rules="dateValidation"
                                              prepend-icon="event" class="pa-0"
                                              label="Sod Date"
                                              v-model="sdate"/>
                            </v-flex>
                            <v-flex xs4 sm4 v-if="status=='today'">
                                <v-text-field autocomplete="off" class="pa-0"
                                              label="Search"
                                              v-model="name"/>
                            </v-flex>

                            <v-spacer></v-spacer>
                            <v-btn fab dark small color="red" @click="status = 'today'">
                                <v-icon>view_column</v-icon>
                            </v-btn>
                            <v-btn fab dark small color="green" @click="status = 'default'">
                                <v-icon>vertical_split</v-icon>
                            </v-btn>
                        </v-card-title>
                    </v-card>
                    <v-data-table v-if="status == 'default'" :headers="headers" hide-default-footer
                                  :items="form.items.data" :search="search"
                                  :options.sync="pagination" :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td class="text-xs-left">{{ index +1}}</td>

                                <!--                                <td class="text-xs-left">{{item.bill_date}}</td>-->
                                <td class="text-xs-left">{{item.bill_month}}</td>
                                <!--                                bill amount-->
                                <td class="text-xs-left">{{item.billing_amount.currency()}}</td>
                                <td class="text-xs-left">{{((item.collection_amount) + (extra[item.bill_month] ? extra[item.bill_month] : 0 )).currency()}}
<!--                                    -&#45;&#45; {{extra[item.bill_month] }}-->
                                </td>
                                <td class="text-xs-left">{{item.instant_discount.currency()}}</td>
                                <td class="text-xs-left">{{item.due_amount.currency()}}</td>

                            </tr>
                        </template>
                        <template slot="footer">
                            <tr>
                                <th :colspan="2" class="text-xs-center">Total</th>
                                <th class="text-xs-left">{{totalBillAmount.currency()}}</th>
                                <th class="text-xs-left">{{totalCollectionAmount.currency()}}</th>
                                <th class="text-xs-left">{{totalInstantDiscountAmount.currency()}}</th>
                                <th class="text-xs-left">{{(totalBillAmount- totalCollectionAmount).currency()}}</th>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                    <v-data-table v-if="status == 'today'" :headers="studentHeaders"
                                  :items="studentsCollection.data" :search="search"
                                  footer-props.items-per-page-options="rowsPerPageItems"
                                  :server-items-length="studentsCollection.meta.total"
                                  :options.sync="paginationStudent" :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td class="text-xs-left">{{index +1 }}</td>

                                <td class="text-xs-left">{{item.name}}
                                    <br/><span>{{item.enroll_code}}</span></td>
                                <td class="text-xs-left">{{item.roll}}</td>
                                <td class="text-xs-left">{{item.grade}}<sup>"{{item.section}}"</sup></td>
                                <td class="text-xs-left">{{item.amount.currency()}}</td>
                            </tr>
                        </template>
                        <template v-slot:body.append
                                  v-if="studentsCollection && studentsCollection.data && studentsCollection.data.length > 0">
                            <tr>
                                <td colspan="4" class="text-xs-right"><strong>Total Amount:</strong></td>
                                <td><strong>{{totalSum.currency()}}</strong></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import {validateDate} from "../../../../library/helpers";

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    export default {
        data: () => ({
            gradeLoading: false,
            form: new Form({
                bill_month: '',
                grade_id: '',
                enroll: '',

            }, '/api/report/bill-collection'),
            search: null,
            pagination: {
                rowsPerPage: 10
            },
            paginationStudent: {
                rowsPerPage: 10
            },
            dateValidation: [
                (v) => validateDate(v) || 'Invalid Date Format',
            ],
            fab: false,
            status: 'default',
            name: '',
            totalSum: 0,
            studentsCollection: [],
            rowsPerPageItems: [5, 10, 25, 50, 75],
            sdate: nd.format('YYYY-MM-DD'),
            // sdate: '2076-02-08',
            headers: [
                {text: 'S. No', align: 'left', value: 'id', sortable: false},
                // {text: 'Date', align: 'left', value: 'bill_date', sortable: false},
                {text: 'Month', align: 'left', value: 'bill_date', sortable: false},
                {text: 'Bill Amount', align: 'left', value: 'bill_amount', sortable: false},
                {text: 'Collected Amount', align: 'left', value: 'collection_amount', sortable: false},
                {text: 'Instant Discount', align: 'left', value: 'instant_discount', sortable: false},
                {text: 'Due Amount', align: 'left', value: 'due_amount', sortable: false},

            ],
            studentHeaders: [
                {text: 'S. No', align: 'left', value: 'id', sortable: false},
                {text: 'Name', align: 'left', value: 'name', sortable: false},
                {text: 'Roll', align: 'left', value: 'roll', sortable: false},
                {text: 'Grade', align: 'left', value: 'grade', sortable: false},
                {text: 'Paid Amount', align: 'left', value: 'amount', sortable: false}

            ],
            grades: [],
            grade: '',
            sections: [],
            section: '',
            collection: [],
            extra: [],
            totalCollectionAmount: 0,
            totalBillAmount: 0,
            totalInstantDiscountAmount: 0,

        }),

        computed: {
            ...mapState(['batch']),
        },

        mounted() {
            this.getGrades();
            this.getTodayStudentCollection();
        },

        watch: {
            'batch': function (value) {
                this.get();
            },

            'grade': function (value) {
                this.section = '',
                    this.getSection();
                this.get();

            },
            'section': function (value) {
                // this.form.items.data = [];
                this.get();
            },
            'name': function () {
                // console.log('test...');
                this.getTodayStudentCollection();
            },
            'sdate': function () {
                this.getTodayStudentCollection();
            },
            'pagination': function () {
                this.get();
            },
            'paginationStudent': function () {
                this.getTodayStudentCollection();
            },
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&sectionId=' + this.section + '&gradeId=' + this.grade;
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.collection = data.collection;

                    this.totalCollectionAmount = 0;
                    this.totalBillAmount = 0;
                    this.totalInstantDiscountAmount = 0;
                    this.extra = data.extra;

                    //collection sum amount
                    // data.collection.map(res => {
                    //     this.totalCollectionAmount += parseFloat(res.total_amount);
                    // });

                    // bill Sum amount
                    data.data.map(r => {
                        this.totalBillAmount += parseFloat(r.billing_amount);
                        this.totalCollectionAmount += parseFloat(r.collection_amount)  + (this.extra[r.bill_month] ? this.extra[r.bill_month] :0);
                        this.totalInstantDiscountAmount += parseFloat(r.instant_discount);
                    });

                    this.pagination.totalItems = data.meta.total
                })
            },

            save() {
                this.form.batchId = this.batch.id;
                this.form.grade_id = this.grade;
                this.form.bill_month = this.month;
                this.form.store()
            },

            getGrades() {
                this.gradeLoading = true;
                this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true').then(({data}) => {
                    this.grades = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                }).finally(() => {
                    this.gradeLoading = false;
                })
            },

            getSection() {
                if (this.grade) {
                    this.$rest.get('/api/section?grade=' + this.grade).then(({data}) => {
                        this.sections = data.data.map(item => {
                            return {value: item.id, text: item.name}
                        });
                    })
                }
            },
            getTodayStudentCollection() {
                // console.log(this.paginationStudent);
                this.sdate = this.sdate.dateForm();
                if (this.sdate.length == 10) {
                    this.$rest.get('/api/report/sod-wise-transaction?sodDate=' + this.sdate + '&name=' + this.name + '&rowsPerPage=' + this.paginationStudent.rowsPerPage).then(res => {
                        this.studentsCollection = res.data;
                        if (this.studentsCollection.data.length < 0) studentsCollection.data.meta.total = 0;
                        this.totalSum = parseFloat(res.data.sum_amount);
                    })
                }

            },
            dateFormat(data) {
                if (data.length === 8) return data = data.substr(0, 4) + '-' + data[4] + data[5] + '-' + data.slice(-2);
                else return data;
            },
            monthName(month) {
                switch (month) {
                    case '01':
                        return "Baishakh"
                        break;
                    case '02':
                        return "Jestha"
                        break;
                    case '03':
                        return "Ashadh"
                        break;
                    case '04':
                        return "Shrawan"
                        break;
                    case '05':
                        return "Bhadra"
                        break;
                    case '06':
                        return "Ashoj"
                        break;
                    case '07':
                        return "Kartik"
                        break;
                    case '08':
                        return "Mangsir"
                        break;
                    case '09':
                        return "Poush"
                        break;
                    case '10':
                        return "Magh"
                        break;
                    case '11':
                        return "Falgun"
                        break;
                    case '12':
                        return "Chaitra"
                        break;
                }
            }

        }
    }
</script>